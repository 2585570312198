var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_main_warehouse),expression:"is_main_warehouse"}],class:_vm.$style.table,attrs:{"headers":_vm.main_warehouse_cols,"items":_vm.items,"show-select":"","options":_vm.table_options,"loading":_vm.cart_loading,"has_sticky_cells":"","hide-default-footer":"","expanded":_vm.expanded,"show-expand":"","not_resizeble":true},on:{"update:options":function($event){_vm.table_options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"disabled":!item.license_key,"icon":"","x-small":""},on:{"click":function($event){return _vm.expand_clicked(item)}}},[(!_vm.is_expanded(item))?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_vm._e(),(_vm.is_expanded(item))?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_vm._e()],1)],1)]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('td',{class:_vm.$style.checkbox},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","value":isSelected},on:{"change":function($event){return select($event)}}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{class:_vm.$style.licenseKeyText},[_vm._v(" Лицензионный ключ: ")]),_vm._v(" "+_vm._s(item.license_key)+" "),_c('v-btn',{attrs:{"icon":"","small":"","color":"accent darken-4"},on:{"click":function($event){return _vm.edit_license_key(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.product.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{class:_vm.$style.nomenclatureLink,attrs:{"to":_vm.go_to_nomenclature(item.product.id)}},[_vm._v(" "+_vm._s(item.product.nomenclature)+" ")])],1)]}},{key:"item.product.product_code",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.product.product_code)+" ")])])]}},{key:"item.available_balances",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.available_balances)+" ")])])]}},{key:"item.available_post_production_residues",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.available_post_production_residues)+" ")])])]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _vm.click_outside,
          include: _vm.include_handler,
        }),expression:"{\n          handler: click_outside,\n          include: include_handler,\n        }"}],staticClass:"included_class",class:_vm.$style.paymentMethod,on:{"click":function($event){return _vm.clicked_payment_handler(item.id)}}},[(_vm.clicked_payment === item.id && _vm.has_organization)?_c('Select',{attrs:{"value":item.payment_type,"items":_vm.payment_options},on:{"input":function($event){return _vm.set_payment_type(item.id, $event, item.quantity)}}}):[_vm._v(" "+_vm._s(_vm.get_payment_type(item))+" ")]],2)]}},{key:"item.prices.rub.value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.value))+" ")])])]}},{key:"item.prices.rub.total_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_value))+" ")])])]}},{key:"item.prices.rub.total_nds_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_nds_value || 0))+" ")])])]}},{key:"item.percent_extra_charge",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:[_vm.$style.percentExtraCharge, 'text-caption']},[_vm._v(_vm._s(item.prices.markup)+"%")])])]}},{key:"item.prices.rub.total_sale_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_c('b',[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_sale_value))+" ")])])])]}},{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [(_vm.project_choosen)?_c('TextField',{class:_vm.$style.quantityInput,attrs:{"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":item.max_project_quantity,"min":1,"disabled":_vm.is_qty_disabled(item)},on:{"input":function($event){return _vm.upd_product_qty(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}):_c('TextField',{class:_vm.$style.quantityInput,attrs:{"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":1,"disabled":_vm.is_qty_disabled(item)},on:{"input":function($event){return _vm.upd_product_qty(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary darken-4"},on:{"click":function($event){return _vm.del_from_cart(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true),model:{value:(_vm.selected_items_model),callback:function ($$v) {_vm.selected_items_model=$$v},expression:"selected_items_model"}}),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_main_warehouse),expression:"!is_main_warehouse"}],class:_vm.$style.table,attrs:{"headers":_vm.other_warehouse_cols,"items":_vm.items,"show-select":"","options":_vm.table_options,"loading":_vm.cart_loading,"has_sticky_cells":"","hide-default-footer":"","expanded":_vm.expanded,"show-expand":"","not_resizeble":true},on:{"update:options":function($event){_vm.table_options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"disabled":!item.license_key,"icon":"","x-small":""},on:{"click":function($event){return _vm.expand_clicked(item)}}},[(!_vm.is_expanded(item))?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_vm._e(),(_vm.is_expanded(item))?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_vm._e()],1)],1)]}},{key:"item.data-table-select",fn:function(ref){
        var isSelected = ref.isSelected;
        var select = ref.select;
return [_c('td',{class:_vm.$style.checkbox},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","value":isSelected},on:{"change":function($event){return select($event)}}})],1)]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{class:_vm.$style.licenseKeyText},[_vm._v(" Лицензионный ключ: ")]),_vm._v(" "+_vm._s(item.license_key)+" "),_c('v-btn',{attrs:{"icon":"","small":"","color":"accent darken-4"},on:{"click":function($event){return _vm.edit_license_key(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.product.product_code",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.product.product_code)+" ")])])]}},{key:"item.product.nomenclature",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('router-link',{class:_vm.$style.nomenclatureLink,attrs:{"to":_vm.go_to_nomenclature(item.product.id)}},[_vm._v(" "+_vm._s(item.product.nomenclature)+" ")])],1)]}},{key:"item.available_balances",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.available_balances)+" ")])])]}},{key:"item.available_post_production_residues",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.available_post_production_residues)+" ")])])]}},{key:"item.payment_type",fn:function(ref){
        var item = ref.item;
return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _vm.click_outside,
          include: _vm.include_handler,
        }),expression:"{\n          handler: click_outside,\n          include: include_handler,\n        }"}],staticClass:"included_class",class:_vm.$style.paymentMethod,on:{"click":function($event){return _vm.clicked_payment_handler(item.id)}}},[(_vm.clicked_payment === item.id && _vm.has_organization)?_c('Select',{attrs:{"value":item.payment_type,"items":_vm.payment_options},on:{"input":function($event){return _vm.set_payment_type(item.id, $event, item.quantity)}}}):[_vm._v(" "+_vm._s(_vm.get_payment_type(item))+" ")]],2)]}},{key:"item.prices.rub.value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.value))+" ")])])]}},{key:"item.prices.rub.total_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_value))+" ")])])]}},{key:"item.prices.rub.total_nds_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_nds_value || 0))+" ")])])]}},{key:"item.percent_extra_charge",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:[_vm.$style.percentExtraCharge, 'text-caption']},[_vm._v(_vm._s(item.prices.markup)+"%")])])]}},{key:"item.prices.rub.total_sale_value",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('div',{class:_vm.$style['table-currency']},[_c('b',[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.rub.total_sale_value))+" ")])])])]}},{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [(_vm.project_choosen)?_c('TextField',{class:_vm.$style.quantityInput,attrs:{"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":item.max_project_quantity,"min":1,"disabled":_vm.is_qty_disabled(item)},on:{"input":function($event){return _vm.upd_product_qty(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}):_c('TextField',{class:_vm.$style.quantityInput,attrs:{"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":1,"disabled":_vm.is_qty_disabled(item)},on:{"input":function($event){return _vm.upd_product_qty(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary darken-4"},on:{"click":function($event){return _vm.del_from_cart(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true),model:{value:(_vm.selected_items_model),callback:function ($$v) {_vm.selected_items_model=$$v},expression:"selected_items_model"}}),_c('Snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","color":"#fff","title":"Отменить"},on:{"click":_vm.clear_selected}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('span',[_vm._v(" Выбрано товаров: "+_vm._s(_vm.selected_items_model.length)+" ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"text":""},on:{"click":_vm.delete_dialog_open}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-delete-outline")]),_c('span',[_vm._v(" Удалить товары ")])],1),_c('v-btn',{attrs:{"text":"","disabled":!_vm.has_organization},on:{"click":function($event){_vm.change_payment_dialog = true}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v(" Изменить форму оплаты ")])],1)],1),_c('ChangePaymentDialog',{attrs:{"items":_vm.selected_items_model},on:{"close":function($event){_vm.change_payment_dialog = false},"upd_method":_vm.update_payment_type},model:{value:(_vm.change_payment_dialog),callback:function ($$v) {_vm.change_payment_dialog=$$v},expression:"change_payment_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }